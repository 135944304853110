$logo-size: 55px;

.btn.register {
    margin: 0;
}

.event-list {
    list-style: none;
}

.event-list-item-global {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
}

.event-item-content-container{
    flex-grow: 1;
    box-sizing: border-box;
    display: inline-block;
}

.event-item-theme-logo {
    display: inline-block;
    max-width: $logo-size;
    max-height: $logo-size;
    flex-grow: 1;
}

.event-item-logo {
    display: flex;
    flex-direction: column;
}

.event-item-logo-date {
    font-size: small;
    max-width: $logo-size;
    text-align: center;
    margin-right: 25px;
    color: gray;
    margin-top: 5px;
}

.event-item-date-info{
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    font-size: small;
    color: gray;
}

#event-show-past-events {
    float: right;
    color: gray;
    font-size: small;
    margin-right: 20px;
    margin-top: 20px;
}
#event-show-past-events:hover{
    cursor: grab;
}

#event-footer {
    float: right;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
    width: 100%;
}

.event-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: space-between;
    align-items: stretch;
    padding-right: 1rem;
}

#event-footer .material-symbols-outlined{
    padding: 0 0.2em;
}

#event-footer {
    .nowrap{
        display: flex;
        flex-wrap: nowrap;
    }
    span {
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }
}

.event-item-end-date {
    margin-right: 1rem;
}

.event-date-separator-dash {
    padding: 0 0.2em;
}

@media (min-width: 768px){
    .event-item-date-info{
        display: none;
    }
}


@media (max-width:768px){
    .event-item-logo {
        display: none;
    }
    .event-item-theme-logo {
        display: none;
    }
}